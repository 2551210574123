@font-face {
  font-family: 'Graphik';
  src: url('Graphik-Regular.woff2');
  src: url('Graphik-Regular.woff') format('woff'), url('Graphik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik';
  src: url('Graphik-Semibold.woff2');
  src: url('Graphik-Semibold.woff') format('woff'), url('Graphik-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik';
  src: url('Graphik-Bold.woff2');
  src: url('Graphik-Bold.woff') format('woff'), url('Graphik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
