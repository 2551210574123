.container-mew-modal {
  border-bottom: 10px solid #449764;
}
#Attach-Mew-Wallet-Modal {
  .mew-wallet-modal-content {
    overflow: hidden;
  }
  .upper-text .mew-text-one {
    color: #449764;
    margin-top: 35px;
    font-family: 'Graphik', sans-serif;
    font-weight: bold;
  }
}
