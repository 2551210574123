@import './brand';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@media (min-width: 1921px) {
  .curr-page-content {
    background-color: $fallbackColor;
    margin-top: -19px;
  }
  .mui-list-style-override {
    position: relative;
    z-index: 1;
  }
}

html {
  scroll-behavior: smooth;
  color: $secondaryColor;
}

.App {
  .MuiAlert-standardError,
  .MuiAlert-standardSuccess,
  .MuiAlert-standardError,
  .MuiAlert-standardWarning {
    background-color: $secondaryColor !important;

    .MuiAlert-message {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .MuiBox-root.jss1 {
    padding: 11px;
  }
  .wallet-button {
    font-weight: 400 !important;
    font-size: 40px;
    padding: 12px 100px;
    border-radius: 56px !important;
    float: left;
    font-variant: unset;
    position: absolute;
    left: 85px;
    width: 450px;
    margin-top: 26px;
    cursor: pointer !important;
    text-transform: none !important;
    z-index: -1;
  }

  .statistic-box.MuiPaper-rounded {
    border: 2px solid $mainColor;
    color: $mainColor;
    border-radius: 41px;
    background-color: $fallbackColor;
  }

  .summary-box {
    font-size: 26px;
    font-family: $brandFont;
  }

  .statistic-box.MuiPaper-rounded .MuiBox-root {
    color: $mainColor;
    font-weight: 100;
    margin-bottom: -10px;
  }

  .wallet-connector-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 250px;

    @media (min-width: 631px) {
      width: 550px;
    }

    @media (min-width: 1162px) {
      width: 1130px;
    }
  }

  .single-wallet-option {
    cursor: pointer;
    width: 200px;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #282c2a;
    border-radius: 10px;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

    &:hover {
      background-color: $mainColor;

      .wallet-name {
        color: $secondaryColor;
      }
    }
  }

  .wallet-icon {
    height: 40px;
    width: auto;
  }

  .wallet-name {
    font-size: 18px;
    font-weight: 600;
    color: rgba($secondaryColor, 0.65);
  }

  .MuiAlert-standardError .MuiSvgIcon-fontSizeInherit,
  .MuiAlert-standardWarning .MuiSvgIcon-fontSizeInherit {
    font-size: 22px;
    margin-top: 0px;
  }

  .welcome-text .subtext-highlighted {
    color: $mainColor;
    margin-top: 10px;
    font-weight: 100;
    font-size: 100px;
  }
  > header .first-box {
    padding: 11px !important;
  }

  .MuiAppBar-positionFixed > .MuiContainer-maxWidthLg {
    max-width: 100%;
    @media (min-width: 1025px) {
      padding: 0 98px;
    }
  }

  .MuiButton-contained.claim-rewards-button,
  .MuiButton-contained.Mui-disabled.claim-rewards-button {
    font-family: 'Graphik', sans-serif;
    font-weight: 500;
    font-size: 34px;
    border-radius: 40px;
    line-height: 40px;
    width: 100%;
    padding: 16px;
    margin-bottom: 100px;
  }
  .MuiButton-contained.Mui-disabled.claim-rewards-button .MuiLoadingButton-loadingIndicator span {
    width: 33px !important;
    height: 33px !important;
  }
  .claiming-error,
  .MuiAlert-standardSuccess {
    margin-bottom: 20px;
  }
  header {
    @media (min-width: 1025px) {
      height: 65px;
    }
  }
}

@import '../styles/mobile';
@import '../styles/mew/style';
