@import './brand';

* {
  font-family: 'Graphik', sans-serif;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  background-color: #1d201f;
  color: #f9f7f0;
}

.btn {
  display: inline-block;
  background-color: $mainColor;
  padding: 22px 40px 20px;
  font-size: 17px;
  text-transform: uppercase;
  border-radius: 50px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #4e4e4e;
  }

  &-outline {
    background-color: transparent;
    border: 2px solid #7b7b7b;
    color: #7b7b7b;
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
      background-color: transparent;
      border: 2px solid $mainColor;
      color: $mainColor;
    }
  }

  &-no-hover {
    cursor: not-allowed;
    opacity: 0.65;

    &:hover {
      background-color: $mainColor;
    }
  }
}

.App {
  text-align: center;

  .MuiTypography-h6,
  .MuiButton-contained,
  .MuiPaper-rounded .MuiBox-root {
    font-family: $brandFont;
    font-weight: bold;
  }

  .MuiAppBar-colorPrimary {
    color: #1d201f;
    background-color: #f9f7f0;
  }

  .MuiPaper-rounded {
    background-color: #f9f7f0;
    font-weight: bold;
  }

  .MuiPaper-rounded .MuiTypography-root {
    font-family: $brandFont;
    font-weight: normal;
  }

  .wallet-name span {
    font-size: 35px;
    font-family: $brandFont;
    font-weight: bold;
    margin-left: 10px;
  }

  .wallet-name.walletconnect span {
    margin-left: 20px;
  }
  .MuiButton-root {
    border-radius: 20px;
  }

  .MuiButton-contained.Mui-disabled {
    font-weight: 100;
    font-size: 20px;
    color: $mainColor;
    border: 1px solid $mainColor;
    background-color: #f9f7f0;
    .MuiButton-label {
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      padding: 1px;
    }
  }

  .MuiButton-containedPrimary:hover {
    background-color: $mainColorFocused;
  }

  .MuiButton-containedPrimary {
    background-color: $mainColor;
  }

  .page-description-box {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 300px;
    margin-top: 65px;
    padding: 30px;
    border-bottom: 1px solid $mainColor;
    background-image: url('../images/bg-lion.jpg');
    background-size: cover;
    background-position: center;

    @media (min-width: 631px) {
      min-height: 450px;
    }

    @media (min-width: 1162px) {
      min-height: 600px;
    }

    h1 {
      margin: 0 auto;
      max-width: 900px;
      font-size: 50px;
      text-transform: uppercase;
      text-align: center;
      line-height: 0.85;

      @media (min-width: 631px) {
        font-size: 80px;
      }

      @media (min-width: 1162px) {
        font-size: 110px;
      }
    }

    .brand-green {
      color: $mainColor;
    }
  }

  .app-bar-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & a {
      line-height: 0;
    }
  }

  .top-details {
    display: flex;
    align-items: center;
    padding: 11px;
  }

  .app-title {
    margin-top: 2px;

    .app-title-text {
      font-size: 15px;
      font-weight: 700;
      margin-right: 100px;
      text-transform: uppercase;
      line-height: 0;
    }
  }

  .site-separator {
    border-bottom: 10px solid rgb(68, 151, 100);
    margin-top: 324px;
    margin-left: 105px;
    margin-right: 105px;
    @media (max-width: 1161px) {
      display: none;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.welcome-text {
  font-size: 35px;
  font-family: $brandFont;
  font-weight: 600;
  padding: 60px 0 30px;
}

@import '../fonts/Graphik/font.css';
