@media (max-width: 490px) {
  .App .MuiButtonGroup-contained .MuiButton-contained.Mui-disabled .MuiButton-label,
  .App .MuiButtonGroup-contained .MuiButton-contained.fake-disabled .MuiButton-label {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 89px;
  }
}

@media (max-width: 420px) {
  .App .MuiButtonGroup-contained .MuiButton-contained.Mui-disabled:first-child {
    display: none;
  }

  .App .MuiButtonGroup-groupedHorizontal:not(:first-child) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

#root .App .app-title {
  display: none;
}

@media (min-width: 756px) {
  #root .App .app-title {
    display: block;
  }
}
@media (max-width: 1024px) {
  .App .MuiAppBar-positionFixed > .MuiContainer-maxWidthLg {
    height: 65px;
  }
}
@media (min-width: 631px) and (max-width: 1161px) {
  .App .page-description-box .page-description-holder .page-description-small {
    line-height: 75px;
  }
  .App .page-description-box .page-description-holder .large {
    line-height: 120px;
  }
}

@media (max-width: 800px) and (min-width: 631px) {
  .App .MuiContainer-root.page-description-box .page-description-holder .page-description-large {
    font-size: 154px;
    line-height: 120px;
  }
}

@media (max-width: 750px) and (min-width: 631px) {
  #root
    .App
    .MuiContainer-root.page-description-box
    .page-description-holder
    .page-description-large {
    font-size: 125px;
    line-height: 98px;
  }
}

@media (max-width: 630px) {
  .App .MuiContainer-root.page-description-box .page-description-holder .page-description-large {
    text-align: center;
    font-size: 75px;
    line-height: 62px;
  }
}
@media (max-width: 630px) {
  .App {
    .mui-list-style-override {
      padding-left: 0 !important;
    }
    .MuiAppBar-positionFixed {
      .logo-holder {
        margin-right: 0;
        margin-bottom: 10px;
      }
      > .MuiContainer-maxWidthLg {
        .MuiBox-root.app-title {
          overflow: hidden;
          width: 100%;
          display: block;
          text-align: center;
          margin: auto;
        }
      }

      padding: 0;
    }
    .MuiContainer-root.page-description-box .page-description-holder {
      .page-description-small {
        font-size: 50px;
        line-height: 41px;
        text-align: center;
      }

      padding-left: 0;
    }

    .welcome-text {
      font-size: 28px;
      font-family: $brandFont;
      font-weight: 600;
      padding: 40px 0 30px;
    }
    .single-wallet-option {
      text-align: left;
    }
  }
}
@media (max-width: 1161px) {
  .App .MuiContainer-root.page-description-box .page-description-holder {
    padding-left: 0;
    text-align: center;
  }
}
@media (max-width: 630px) {
  .App .MuiAppBar-positionFixed > .MuiContainer-maxWidthLg .app-title .app-title-text {
    padding: 20px 0;
    width: 100%;
    margin: auto;
    text-align: center;
  }
}
